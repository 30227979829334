import {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Alert, Box, Grid, SelectChangeEvent} from '@mui/material';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import GeneralSelect, {GeneralSelectOption} from 'src/components/GeneralSelect/GeneralSelect';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import {useScreenSize} from 'src/hooks/useScreenSize';
import Breakpoint from '../../types/BreakPoint.enum';
import {updateTabTitle} from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import {PolicyComponentQuestions} from 'src/components/PolicyComponentQuestions/PolicyComponentQuestions';
import {PolicySubcategory} from 'src/types/PolicySubcategory';
import {POLICY_DATA} from 'src/const/PolicyData.graphql';
import {PolicyAnswer} from 'src/types/PolicyAnswer';
import styles from './StatePolicyDesc.module.scss';
import {PolicyQuestion} from 'src/types/PolicyQuestion';
import {RecentLegislation} from '../../components/RecentLegislation/RecentLegislation';
import PolicyLinks from '../../components/PolicyLinks/PolicyLinks';
import {StateComparisonButton} from 'src/components/StateComparisonButton/StateComparisonButton';
import {PolicyProfileSummary} from '../../components/PolicyProfileSummary/PolicyProfileSummary';
import {
  DownloadGapAnalysisAndPrintPage
} from '../../components/DownloadGapAnalysisAndPrintPage/DownloadGapAnalysisAndPrintPage';
import {StateOptionEnum} from '../../types/StateOption.enum';
import formatEnumToString from '../../utils/formatEnumToString';
import {getStateOptions} from '../../utils/getStateOptions';
import getInitialStateValue from '../../utils/getInitialStateValue';
import getEnumDisplayText from '../../utils/getEnumDisplayText';
import getEnumUrlText from '../../utils/getEnumUrlText';
import {filterNumOfYes} from "../../utils/filterNumOfYes";

interface UrlParam {
  [key: string]: string | undefined;
}

interface StatePolicyDescProps {
  printPage: () => void;
}

export const StatePolicyDesc: FC<StatePolicyDescProps> = ({printPage}) => {
  const {stateId, policyId}: UrlParam = useParams();
  const stateOptions: GeneralSelectOption[] = getStateOptions();
  const [policySelected, setPolicySelected] = useState<string>('');
  const [stateSelected, setStateSelected] = useState<StateOptionEnum>(getInitialStateValue(stateId));
  const [initialState] = useState<StateOptionEnum>(getInitialStateValue(stateId));
  const {screenSizeIsAtLeast} = useScreenSize();
  const [policyData, setPolicyData] = useState<PolicySubcategory>(new PolicySubcategory());
  const [policyBrief, setPolicyBrief] = useState<string>('');

  updateTabTitle(PageTitles.POLICY_DESC);

  const {data, loading, error} = useQuery(POLICY_DATA, {
    variables: {stateId: initialState},
  });

  const breadCrumbsState = getEnumDisplayText(stateId);
  const subcategoryTitle: string = policyId?.replace(/_/g, ' ').replace(/&/g, '/') || '';

  useEffect(() => setPolicySelected(subcategoryTitle), [subcategoryTitle]);
  useEffect(() => {
    if (data) {
      setPolicyData(data.listActivePolicySubcategories.find((p: PolicySubcategory) => p.title === subcategoryTitle));
      setPolicyBrief(
        data.listActivePolicySubcategories.filter((p: PolicySubcategory) => p.title === subcategoryTitle)[0]
          .policyBrief,
      );
    }
  }, [data, subcategoryTitle]);

  if (loading) return <>Loading</>;
  if (error || !data || data.length === 0) {
    return (
      <Alert severity="warning">
        Unable to load State Policy information. Please try again or contact support if the issue persists.
      </Alert>
    );
  }

  const questions = data.listPolicyQuestions.filter(
    (question: PolicyQuestion) => question.subcategory.title === subcategoryTitle,
  );
  const answers = data.getPolicyAnswersByStateId.filter((answer: PolicyAnswer) => filterNumOfYes({
    answer: answer.question.subcategory.title,
    subcategory: subcategoryTitle,
    questionText: answer.question.text,
  }))


  const totalYesAnswers = answers.reduce(
    (count: number, answer: PolicyAnswer) => (answer.value ? count + 1 : count),
    0,
  );

  const handleChangePolicy = (e: SelectChangeEvent) => {
    setPolicySelected(e.target.value);
  };

  return (
    <div className={styles.print}>
      <BreadCrumbs stateName={breadCrumbsState} policyName={subcategoryTitle.replace(/\//g, '&')}/>
      <div className={styles.headingContainer}>
        <div>
          <div className={styles.headingText}>
            <Heading headingStyle={HeadingStyle.PAGE_TITLE}>State Policy Description</Heading>
          </div>
        </div>
      </div>
      <div className={styles.selectLine}>
        <span>You are viewing</span>
        <div className={styles.stateSelectContainer}>
          <GeneralSelect
            id="stateSelector"
            labelText="Choose another state"
            data={stateOptions}
            value={stateSelected}
            onChange={(e) => setStateSelected(e.target.value as StateOptionEnum)}
            defaultStyle={true}
            width="100%"
            capitalizeOptions={true}
          />
        </div>
        <span>and</span>
        <div className={styles.policySelectContainer}>
          <GeneralSelect
            id="policySelector"
            labelText="Choose another policy"
            data={data.listActivePolicySubcategories.map((subcategory: PolicySubcategory) => subcategory.title).sort()}
            value={policySelected}
            onChange={handleChangePolicy}
            defaultStyle={true}
            width="100%"
          />
        </div>
        <Box className={screenSizeIsAtLeast[Breakpoint.LG] ? styles.buttonContainer : styles.buttonPositionDiv}>
          <PrimaryButton
            id={styles.billButton}
            text="Go"
            onClick={() => {
              window.location.href =
                `/state/${getEnumUrlText(stateSelected)}/${policySelected.replace(/\s+/gi, '_').replace(/\//g, '&')}`
            }}
          />
        </Box>
      </div>
      <PolicyProfileSummary policy={policyData} policyBrief={policyBrief}/>
      <StateComparisonButton policySelected={policySelected} stateSelected={initialState}/>
      <div className={styles.stateName}>{formatEnumToString(initialState)}</div>
      <PolicyComponentQuestions
        title={subcategoryTitle}
        answers={answers ? answers : questions}
        questions={questions}
        totalYesAnswers={totalYesAnswers}
      />
      <DownloadGapAnalysisAndPrintPage printPage={printPage}/>
      <Box marginBottom={'1rem'}>
        <div className={styles.moreInformation}>More Information</div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {stateId && policyData && (
              <RecentLegislation state={initialState} policyId={policyData.id}/>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <PolicyLinks policyId={policyData.id} state={initialState}/>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
